import type { InjectionKey } from 'vue'
import { useBonusesDeps } from '../useDeps'

interface BonusParams {
  id: string | number
  type:
    | 'deposit'
    | 'cash'
    | 'freespin'
    | 'freebet'
    | 'cashback'
    | 'rakeBack'
    | 'reload'
    | 'ratRace'
    | 'vip'
}

export type BonusTooltipType =
  | 'depositBonusTooltip'
  | 'cashBonusTooltip'
  | 'freespinTooltip'
  | 'freebetTooltip'
  | 'regularBonusTooltip'

export interface SubmodalTooltipParams {
  bonusId: number | string
  modalName: BonusTooltipType
}

interface SubmodalTooltipData {
  is: Component
  id: string | number
}
interface UseModalBonusTooltipReturn {
  showTooltip: () => void
  tooltipName: ComputedRef<BonusTooltipType | ''>
  tooltipTitle: ComputedRef<string>
}

export interface SubmodalProvidedData {
  showTooltip: (params: SubmodalTooltipParams) => void
  hideTooltip: () => void
}

type UseSubmodalBonusTooltipReturn = Omit<
  SubmodalProvidedData,
  'isSubmodal'
> & {
  tooltipData: Ref<SubmodalTooltipData | null>
}

export const submodalDataInjectionKey = Symbol(
  'submodalData',
) as InjectionKey<string>

export function useModalBonusTooltip(
  bonusParams: Ref<BonusParams> | BonusParams,
): UseModalBonusTooltipReturn {
  const { t } = useI18n()
  const router = useRouter()

  const tooltipName = computed<BonusTooltipType | ''>(
    () =>
      (
        ({
          deposit: 'depositBonusTooltip',
          cash: 'cashBonusTooltip',
          freespin: 'freespinTooltip',
          freebet: 'freebetTooltip',
          cashback: 'regularBonusTooltip',
          rakeBack: 'regularBonusTooltip',
          reload: 'regularBonusTooltip',
          ratRace: '',
          vip: '',
        }) as Record<string, BonusTooltipType | ''>
      )[unref(bonusParams).type],
  )
  const tooltipTitle = computed(
    () =>
      ({
        deposit: t('bonuses.aboutDeposit'),
        cash: t('bonuses.aboutDeposit'),
        freespin: t('bonuses.aboutFreespins'),
        freebet: t('bonuses.aboutFreebet'),
        cashback: t('bonuses.aboutCashback'),
        rakeBack: t('bonuses.aboutRakeBack'),
        reload: t('bonuses.aboutReload'),
        ratRace: '',
        vip: '',
      })[unref(bonusParams).type] ?? '',
  )

  function openModalTooltip() {
    if (!tooltipName.value) return

    router.replace({
      query: {
        modal: tooltipName.value,
        id: unref(bonusParams).id,
      },
    })
  }

  return {
    // computeds
    tooltipName,
    tooltipTitle,
    // methods
    showTooltip: openModalTooltip,
  }
}

export function useSubmodalBonusTooltip(): UseSubmodalBonusTooltipReturn {
  const { bonusesTooltips } = useBonusesDeps()

  const tooltipData = ref<SubmodalTooltipData | null>(null)

  function openSubmodalTooltip(params: SubmodalTooltipParams) {
    tooltipData.value = {
      is: bonusesTooltips[params.modalName],
      id: params.bonusId,
    }
  }

  function closeSubmodalTooltip() {
    tooltipData.value = null
  }

  return {
    // refs
    tooltipData,
    // methods
    showTooltip: openSubmodalTooltip,
    hideTooltip: closeSubmodalTooltip,
  }
}
